import React from "react"

import Layout from "../components/layout.en"
import SEO from "../components/seo"

const ContactPage = (props) => (
    <Layout  location={props.location}>
    <SEO title="Contact" />
    <h1>Contact</h1>
      <p>
          You can contact us at <a href={'mailto:contact@analiza-gramaticala.ro'}>contact@analiza-gramaticala.ro</a>
      </p>


  </Layout>
)

export default ContactPage
